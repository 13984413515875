<template>
    <div class="kdsOnItemSet">
        <div class="left-table">
            <el-tree
                ref="treeRef"
                class="filter-tree"
                node-key="id"
                :data="leftTree"
                :expand-on-click-node="false"
                highlight-current
                default-expand-all
                @current-change="currentChange"
            />
        </div>
        <div class="right-table ">
            <div class="title">
                操作作对象: 
                <span v-if="currentNode">{{currentNode.label}}</span>
                <span class="red" v-else>请点击左边列表选择操作对象</span>
                <span class="remark">注：本设置界面只显示KDS需要出现的菜品</span>
            </div>
            <div class="filter-box">
                <!-- <div class="from-box">
                    <div class="lable-txt">1.关联出单位置:</div>
                    <div class="from-input" style="width: 180px;">
                        <el-select v-model="Place_AutoID" multiple collapse-tags placeholder="全选">
                            <el-option :value="item.IssuePlace_AutoID" :label="item.IssuePlace_Name" v-for="item in KitIssuePlace" :key="item"></el-option>
                        </el-select>
                    </div>
                </div> -->
                <div class="from-box">
                    <div class="lable-txt">关联指定菜品:</div>
                    <div class="btn-box">
                        <div class="btn" @click="addFood()">指定菜品</div>
                        <div class="btn" @click="delFood()">删除菜品</div>
                    </div>
                </div>
            </div>
            
            <div class="table-box grey-table">
                <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                    ref="elTable"
                    :data="tableDataSort"
                    highlight-current-row
                    @selection-change="selectFood"
                    :cell-class-name="$parent.setClassName"
                    @cell-dblclick="$parent.dblclick"
                >
                    <el-table-column label="选择" type="selection" width="40" ></el-table-column>
                    <el-table-column prop="Food_Code" label="菜品编码" min-width="100" align="left">
                        <template #default="scope">
                            <span v-copyText>{{scope.row.Food_Code}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Food_Name" label="菜品名称" min-width="100" align="left">
                        <template #default="scope">
                            <span v-copyText>{{scope.row.Food_Name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Food_Price" label="售价" min-width="80" >
                        <template #default="scope">
                            {{scope.row.Food_Price}}/{{scope.row.Food_Size}}
                        </template>
                    </el-table-column>
                    <el-table-column label="是否显示" min-width="50">
                        <template #default="scope">
                            <el-checkbox v-model="scope.row.Chosen_YN" ></el-checkbox>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <teleport to="body">
            <modal class="selectTreeModel" :isShow="isShow">
                <div class="header">选择菜品</div>
                <div class="modal-body">
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchText" placeholder="菜品模糊检索"
                            isBoard isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                    </div>
                    <div class="check">
                        <el-tree
                            :data="treeData"
                            :expand-on-click-node="false"
                            highlight-current
                            :default-expand-all="false"
                            :filter-node-method="filterNode"
                            show-checkbox
                            node-key="id"
                            ref="tree">
                        </el-tree>
                    </div>
                </div>
                <div class="footer-box">
                    <button class="btn btn-cancel" @click="isShow=false">取消</button>
                    <button class="btn btn-confirm" @click="deparConfirm()">确认</button>
                </div>
            </modal>
        </teleport>
    </div>
</template>

<script>
/**KDS关联菜品设置 */
export default {
    name:"kdsOnItemSet",
    props:{
        /**所有菜品 */
        PosFoods:{
            typeof:Array,
            default:null
        },
        /**菜品分类 */
        foodMainCategory:{
            typeof:Array,
            default:null
        },
        /**出单位置 */
        KitIssuePlace:{
            typeof:Array,
            default:null
        },
        /**KDS岗位设备信息 */
        KitPostEquipments:{
            typeof:Array,
            default:null
        }
    },
    computed:{
        leftTree(){
            let tree=[];
            this.KitPostEquipments?.forEach(it=>{
                let children=[];
                it.KdsProjects?.forEach(kds=>{
                    kds.KdsProjectDetails?.forEach(item=>{
                        children.push({
                            id:item.Screen_AutoID,
                            label:(kds.Equipment_Name||kds.Equipment_ID)+"-"+item.Screen_Name,
                            //PosFoods:sub.PosFoods
                        })
                    })
                });
                tree.push({
                    id:it.KitPostID,
                    label:it.KitPostName,
                    children:children
                })
            })
            return [{label:"全部",children:tree,id:0}];
        },
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                category.FoodSubCategorys?.forEach(sub=>{

                    let foods=sub.PosFoods.map((food)=>{
                        return {
                            id:food.Food_ID,
                            label:food.Food_Code+"-"+food.Food_Name,
                            food:food
                        }
                    })
                    tree.push({
                        id:"sub_"+sub.SubCategory_ID,
                        label:(sub.SubCategory_Code+"-"+sub.SubCategory_Name).replace(/^[-]/,""),
                        SubCategory_ID:sub.SubCategory_ID,
                        children:foods
                        //PosFoods:sub.PosFoods
                    })
                });
                
            })
            return tree;
        },
        tableDataSort(){
            return this.tableData?.order("Food_Code","ASC");
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("itemOnKdsSet_pageSize",newVal);
        },
        leftTree(){
            this.$nextTick(()=>{
                if(this.currentNode){
                    this.$refs.treeRef?.setCurrentNode(this.currentNode);
                    this.loadData(true);
                }
            })
        },
        isShow(){
            this.$refs.tree?.setCheckedKeys([]);
            this.searchText="";
        },
        KitIssuePlace(){
            if(this.KitIssuePlace?.length>0 && this.Place_AutoID?.length>0){
                let ids=[];
                this.Place_AutoID.forEach(it=>{
                    if(this.KitIssuePlace.some(kit=>kit.IssuePlace_AutoID==it)){
                        ids.push(it)
                    }
                })
                this.Place_AutoID=ids;
            }
        },
        searchText(newVal){
            this.$refs.tree?.filter(newVal);
        }
    },
    data(){
        return {
            searchText:"",
            /**勾选要删除的菜品 */
            delFoods:[],
            tableData:[],
            //选中的节点
            currentNode:null,
            //搜索数据
            searchTxt:"",
            //岗位
            planInfo:null,
            //设备
            equipmentId:null,
            //出单位置
            Place_AutoID:[],
            isShow:false,
        }
    },
    methods:{
        currentChange(node){
            if(node && !node.children){
                this.currentNode=node;
                this.loadData(true);
            }else{
                this.currentNode=null;
                this.tableData=[];
            }
            console.log(this.$parent.setClassName)
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.food?.Food_Code?.toLocaleUpperCase()?.includes(value) || data.food?.Food_Name?.toLocaleUpperCase()?.includes(value) || data.food?.Food_PinYin?.toLocaleUpperCase()?.includes(value);
        },
        //勾选的菜品
        selectFood(currentRow){
            this.delFoods=currentRow;
        },
        deparConfirm(){
            this.isShow=false;
            let deparList=this.$refs.tree.getCheckedNodes();
            if(deparList.length>0){
                deparList.forEach(it=>{
                    if(it.food && !this.tableData.some(item=>item.Food_ID==it.id)){
                        this.tableData.push({
                            Food_ID:it.id,
                            Food_Name:it.food.Food_Name,
                            Food_Code:it.food.Food_Code,
                            Food_Size:it.food.Food_Size,
                            Food_Price:it.food.Food_Price,
                            MultipleSizeFoods:it.food.MultipleSizeFoods,//多单位
                            SetMealInfo:it.SetMealInfo,
                            Chosen_YN:true
                        })
                    }
                })
            }
        },
        addFood(){
            if(this.currentNode){
                this.isShow=true
            }else{
                this.$message.warning('请先选择左边设备屏幕');
            }
        },
        /**删除菜品 */
        delFood(){
            this.delFoods?.forEach(it=>{
                let index=this.tableData.findIndex(food=>it.Food_ID==food.Food_ID);

                if(index>=0){
                    this.tableData.splice(index,1);
                }
            })
        },
        loadData(isRefresh){
            if(!this.currentNode?.id || (!isRefresh && this.foodLinkKdsScreens?.length>0)){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.GetKdsScreenLinkFoods",{
                KitScreen_ID:this.currentNode?.id,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    let screenLinkFoods=d.ResponseBody;
                    this.tableData=[];
                    if(screenLinkFoods?.Is_ShowAllIssuePosition){//是否显示所有出单位置
                        this.Place_AutoID=[];
                    }else{
                        
                        let KitIssuePlace_Ids=screenLinkFoods?.KitIssuePlace_Ids||[];
                        if(this.KitIssuePlace?.length>0){
                            let ids=[];
                            KitIssuePlace_Ids.forEach(it=>{
                                if(this.KitIssuePlace.some(kit=>kit.IssuePlace_AutoID==it)){
                                    ids.push(it)
                                }
                            })
                            this.Place_AutoID=ids;
                        }
                    }
                    screenLinkFoods?.KitScreenLinkFoods?.forEach(it=>{
                        let food=this.PosFoods?.find(food=>food.Food_ID==it.Food_ID);
                        if(food){
                            this.tableData.push({
                                Food_ID:food.Food_ID,
                                Food_Name:food.Food_Name,
                                Food_Code:food.Food_Code,
                                Food_Size:food.Food_Size,
                                Food_Price:food.Food_Price,
                                MultipleSizeFoods:food.MultipleSizeFoods,//多单位
                                SetMealInfo:food.SetMealInfo,
                                Chosen_YN:it.Chosen_YN
                            })
                        }
                    })
                }
            }).catch(e=>{
                loading.close();
                console.log('e:',e)
                this.$alert('获取KDS屏幕关联菜品数据失败', "提示", {confirmButtonText: "确定"});
            })
        },
        /**保存 菜品关联KDS屏幕设置 */
        save(){
            let data={
                KitScreen_ID:this.currentNode?.id,
                //KitIssuePlace_Ids:this.Place_AutoID,
                KitScreenLinkFoods:this.tableData
            }

            

            if(!data.KitScreen_ID){
                this.$message.warning('请先选择左边设备屏幕');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            data.Operate_User=userInfo?.Login_Name
            this.$httpAES.post("Bestech.CloudPos.KDS.SetKdsScreenLinkFoods",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        },
    }
}
</script>

<style lang="scss">
.selectTreeModel{
    font-size: 12px;
    color: #000000;

    .modal-dialog{ 
        width: 400px;
        max-width: 400px;
       
        .modal-content{
            right: inherit;
            width: 100%;
            border-radius: 8px;
            padding-bottom: 24px;
        }
    }
 
    .header{
        background: #DCE2EA;
        height: 42px;
        line-height: 42px;
        padding-left: 20px;
        border-radius: 8px;
        border-bottom-left-radius:0 ;
        border-bottom-right-radius:0 ;
        font-size: 15px;
        color: #333333;
    }

    .flex-box{
        display: flex;
    } 

    .modal-body{
        padding:0 30px;
        padding-bottom: 40px;
        font-size: 14px;
        
        .li-box{
            margin:10px 0;
        }

        .search-box {
            margin: 10px 12px;
            margin-left: 0;

            .search {
                background: #FFFFFF;
                border: 1px solid #6d6d6d;
                box-shadow: inset 1px 1px 2px 0 rgba(0,0,0,0.30);
                border-radius: 3px;
                margin:0 5px;
                height: 34px;
                line-height: 34px;
                width: 100%;
                text-align: left;
            }

            .jianpan{
                font-size: 20px;
            }

            input{
               
                border: none;
                vertical-align: top;
                padding: 0 30px 0 30px;
                background-color: transparent;
                font-size: 13px;
            }
        }

        .check{
            height: 300px;
            overflow-y: auto;
        }
    }

    .footer-box{
        padding: 0 15px;
        text-align: center;

        .btn{
            background: #E8E8E8;
            border: 1px solid #D5D7DB;
            border-radius: 4px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #487BB1;
            padding: 0;

            &:first-child{
                float:left;
            }

            &:last-child{
                float:right;
                margin-right: 0;
            }

            &.selected{
                background: #487BB1;
                color:#fff;
            }
        }
    }
}

</style>